import { CardImageInterface } from '../interfaces/card-image.interface';
import { Attachment_Thumbnail_Type_Enum, Attachment_Type_Enum } from '@skychute/schema';
import { AttachmentInterface } from '../interfaces/attachment.interface';
import { AttachmentConfigInterface } from '../interfaces/attachment-config.interface';

/**
 * This is heavily used model all over Angular
 */
export class CardImageModel implements CardImageInterface {
  id?: string; // optional
  type?: string; // optional
  productTypes?: string[]; // optional
  name: string;
  downloadURL: string;
  imageURL: string; // always thumbnail with Listing Page or original image
  thumbnailCardURL: string; // always thumbnail with Gallery Card
  viewImageUrl?: string; // always resize with Gallery view
  projectCardURL?: string; // always thumbnail with Project Card
  logoCardURL?: string; // always thumbnail with PROJECT LOGO SM
  contentType?: string;
  //custom fields, used for edit name from the card
  newName?: string;
  isEdit?: boolean;
  newExtension?: string;
  uploadedByTeamId?: string;
  uploadedByUserId?: string;
  logoSmUrl?: string;
  logoSmUrl2?: string;
  logoMdUrl?: string;
  logoMdUrl2?: string;

  constructor(attachment: AttachmentInterface, cloudURI: string) {
    if (!attachment) {
      return;
    }
    this.id = attachment?.id;
    this.name = attachment?.name;
    this.type = attachment?.type;
    this.uploadedByUserId = attachment?.uploaded_by ?? '';
    this.uploadedByTeamId = attachment?.uploaded_by_team_id ?? '';

    const productTypes = attachment?.attachment_product_types ?? [];
    this.productTypes = [];
    if (productTypes.length > 0) {
      productTypes.forEach((productType) => {
        this.productTypes.push(productType.product_type);
      });
    }

    this.downloadURL = attachment?.download_url;
    // generate thumbnail for gallery card
    this.thumbnailCardURL = CardImageModel.getAttachmentWithThumbnail(
      attachment,
      Attachment_Thumbnail_Type_Enum.GalleryCard,
      cloudURI,
    );
    // Generate view image for gallery card
    this.viewImageUrl = CardImageModel.getAttachmentWithThumbnail(
      attachment,
      Attachment_Thumbnail_Type_Enum.GalleryView,
      cloudURI,
    );

    // generate thumbnail for project card
    this.projectCardURL = CardImageModel.getAttachmentWithThumbnail(
      attachment,
      Attachment_Thumbnail_Type_Enum.ProjectCard,
      cloudURI,
    );

    // generate thumbnail for project sm logo
    this.logoCardURL = CardImageModel.getAttachmentWithThumbnail(
      attachment,
      Attachment_Thumbnail_Type_Enum.ProjectLogoSm,
      cloudURI,
    );

    this.logoSmUrl =
      attachment.thumbnails.find(
        (thumb) => thumb.attachment_thumbnail_type === Attachment_Thumbnail_Type_Enum.ProjectLogoSm,
      )?.thumbnail_url ?? '';
    this.logoSmUrl2 =
      attachment.thumbnails.find(
        (thumb) =>
          thumb.attachment_thumbnail_type === Attachment_Thumbnail_Type_Enum.ProjectLogoSmX2,
      )?.thumbnail_url ?? '';
    this.logoMdUrl =
      attachment.thumbnails.find(
        (thumb) => thumb.attachment_thumbnail_type === Attachment_Thumbnail_Type_Enum.ProjectLogoMd,
      )?.thumbnail_url ?? '';
    this.logoMdUrl2 =
      attachment.thumbnails.find(
        (thumb) =>
          thumb.attachment_thumbnail_type === Attachment_Thumbnail_Type_Enum.ProjectLogoMdX2,
      )?.thumbnail_url ?? '';

    // imagURL only generate if file is pdf and ListingPage config is there
    const isPdfFile = attachment.content_type === 'application/pdf';
    this.imageURL = attachment.download_url;
    this.contentType = attachment.content_type;
    if (attachment?.attachment_config) {
      // check ListingPage is there or not
      const config = attachment.attachment_config.filter(
        (c) => c.attachment_thumbnail_type === Attachment_Thumbnail_Type_Enum.ListingPage,
      );
      // generate and get thumbnail if file is pdf and LISTING_PAGE is there inside config otherwise use download url as a imageURL
      if (isPdfFile && config.length > 0) {
        this.imageURL = CardImageModel.getAttachmentWithThumbnail(
          attachment,
          Attachment_Thumbnail_Type_Enum.ListingPage,
          cloudURI,
        );
      }
    }

    // used for edit name in edit screen. for ex edit project screen, edit stage/building screen
    // custom
    this.newName = '';
    this.newExtension = '';
    this.isEdit = false;
  }

  getLogoUrls(): string[] {
    const logos: string[] = [];
    if (this.logoSmUrl) {
      logos.push(this.logoSmUrl2);
    }
    if (this.logoMdUrl2) {
      logos.push(this.logoMdUrl2);
    }
    if (logos.length === 0) {
      logos.push(this.logoCardURL);
    }
    return logos;
  }

  /**
   * This method return final thumbnail URL based on attachment type, exist thumbnail etc...
   * @param attachment
   * @param thumbType
   * @param cloudURI
   */
  static getAttachmentWithThumbnail(
    attachment: AttachmentInterface,
    thumbType: Attachment_Thumbnail_Type_Enum,
    cloudURI: string,
  ): string {
    const name = attachment.download_url;
    // find the thumbnail from list
    const thumbnail = attachment.thumbnails?.filter(
      (thumb) => thumb.attachment_thumbnail_type === thumbType,
    )?.[0];

    // return the thumbnail if exist
    if (thumbnail?.thumbnail_url) {
      return thumbnail?.thumbnail_url;
    }

    // return video thumbnail
    if (attachment?.type === Attachment_Type_Enum.Media) {
      return '/assets/images/video-thumbnail.jpg';
    }

    // return a docs thumbnail if document is docs
    if (name.indexOf('.doc') > -1 || name.indexOf('.docx') > -1) {
      return '/assets/images/word-thumbnail.png';
    }

    // return a xlsx thumbnail if document is xlsx
    if (name.indexOf('.xlsx') > -1 || name.indexOf('.xls') > -1) {
      return '/assets/images/xls-thumbnail.jpg';
    }

    // generate thumbnail if does not exist for pdf file
    if (attachment?.content_type === 'application/pdf') {
      // set default for use if does not exist
      let attachmentConfig: AttachmentConfigInterface = {
        attachment_thumbnail_type: Attachment_Thumbnail_Type_Enum.GalleryCard,
        width: 208,
        height: 208,
        position: 'center',
        fit: 'cover',
      };

      // find appropriate config for generate valid thumbnail
      const config = attachment.attachment_config.filter(
        (c) => c.attachment_thumbnail_type === thumbType,
      );
      // set config from db if found
      if (config.length > 0) {
        attachmentConfig = config[0];
      }

      const url = attachment.download_url;
      const width = attachmentConfig.width;
      const height = attachmentConfig.height;
      const position = attachmentConfig.position;
      const fit = attachmentConfig.fit;

      // prepare a final URL
      return `${cloudURI}/sharp-sky?url=${url}&width=${width}&height=${height}&fit=${fit}&position=${position}&format=png`;
    }

    return attachment.download_url;
  }
}
