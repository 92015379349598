
/* ATTENTION!
This file is automatically generated by scripts/set-env.ts
Please do not change or commit it
*/
import { IEnvironment } from '@skychute/ui-models';
function isEqual(a: any, b: any): boolean {
    return a === b;
}
export const config: IEnvironment = {
    production: isEqual('true', 'true'),
    services: {
        data: {
          endpoint: 'https://feature-pre-1254-hasura.projectre.com.au',
          path: '/v1/graphql',
          logQueries: 'false'
        },
        aws: {
          UserPoolId: 'ap-southeast-2_7XOxYnrwx',
          AppClientId: '1q2s4gvj0vqab060227gcdfdts',
          cloudUri: 'https://uxrnx5afk5.execute-api.ap-southeast-2.amazonaws.com/dev',
        },
        analytics: {
            segment: {
                enabled: isEqual('false', 'true'),
                writeKey: 'CKazVWLN9swSOQAxq5aOOuUzu9MFuARF',
            },
        },
    },
    application: {
        version: '1.92.0',
        gitHead: '25586fc9091d7bfd42b9aa41a73d249c81fdb38f',
        branch: 'feature/PRE-1254',
        buildDate: 'September 11th 2024, 8:48:13 pm',
        buildNumber: '2',
    },
    stripe: {
        publicKey: 'pk_test_51JVQJzICXqe0hIJdVHDRyFoCVJbQwhWPayB7eHg1boYOy7FsCv7Sj1CJSYA2u6vPj4zRNbmsR6tsVu48e0yQEhYT00ycLtgphf'
    },
    addressFinder: {
        key: 'RBHCYUV987D4XKNPT6FL',
        src: 'https://api.addressfinder.io/assets/v3/widget.js'
    },
    courier_push_client_key:'ODM2OTc0NWItODA5NS00OTI3LWE1ZTEtMWI0OGEyZjBkNjg0L3Rlc3Q=',
    novu_push_client_key:'GVO-2E8_6qNx',
};
